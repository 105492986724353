// import axios from 'axios';
import axiosApi from '@/api/index';

//let axiosApiRaw = axios.create({ baseURL: process.env.VUE_APP_BASE_URL });

// axiosApiRaw.defaults.headers.commonRlo['Authorization'] = process.env.VUE_APP_AUTH;

let urlPath = '/api/file';

const config = {
	headers: {
		'Content-Type': 'multipart/form-data',
	},
};

async function upload(files, checkedValues) {
	const formData = new FormData();

	for (let index in files) {
		let file = files[index];
		if (checkedValues === true) {
			formData.append('file', file);
		} else {
			formData.append('file', await resizeImage({ file, maxSize: 1200 }), file.name);
		}
	}

	let res = await axiosApi.post(`${urlPath}`, formData, config);
	return res;
}

async function uploadPdf(files) {
	const formData = new FormData();
	for (let index in files) {
		formData.append('file', files[index]);
	}
	let res = await axiosApi.post(`${urlPath}/pdf`, formData, config);
	return res;
}

async function remove(id) {
	await axiosApi.delete(`${urlPath}/${id}`);
}

function resizeImage(settings) {
	const file = settings.file;
	const maxSize = settings.maxSize;
	const reader = new FileReader();
	const image = new Image();
	const canvas = document.createElement('canvas');

	function dataURItoBlob(dataURI) {
		const bytes =
			dataURI.split(',')[0].indexOf('base64') >= 0
				? window.atob(dataURI.split(',')[1])
				: window.unescape(dataURI.split(',')[1]);
		const mime = dataURI
			.split(',')[0]
			.split(':')[1]
			.split(';')[0];
		const max = bytes.length;
		const ia = new Uint8Array(max);
		for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
		return new Blob([ia], { type: mime });
	}

	function resize() {
		let width = image.width;
		let height = image.height;
		if (width > height) {
			if (width > maxSize) {
				height *= maxSize / width;
				width = maxSize;
			}
		} else {
			if (height > maxSize) {
				width *= maxSize / height;
				height = maxSize;
			}
		}
		canvas.width = width;
		canvas.height = height;
		canvas.getContext('2d').drawImage(image, 0, 0, width, height);
		const dataUrl = canvas.toDataURL('image/jpeg');
		return dataURItoBlob(dataUrl);
	}

	return new Promise((ok, no) => {
		if (!file) {
			return;
		}
		if (!file.type.match(/image.*/)) {
			no(new Error('Not an image'));
			return;
		}
		reader.onload = readerEvent => {
			image.onload = () => {
				return ok(resize());
			};
			image.src = readerEvent.target.result;
		};
		reader.readAsDataURL(file);
	});
}

export default { upload, uploadPdf, remove };
